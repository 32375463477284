import instance from "./instance";

const ImageUpload = payload => {
  return instance.instanceToken
    .post("/image", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

export default {
  imageUpload: ImageUpload,
};
